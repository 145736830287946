export default [
  {
    path: '/unset-rejected-part-from-stock',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'UnsetRejectedPartFromStockContainer.vue',
        component: () => import('./containers/UnsetRejectedPartFromStockContainer.vue'),
      },
    ],
  },
]
