export default [
  {
    path: '/export',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Export',
        component: () => import('./containers/ExportContainer'),
      },
    ],
  },
]
