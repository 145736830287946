import CrudRepository from './crud-repository'

export function getErrorCode (error) {
  if (error?.message === 'Network Error') {
    return 'NetworkError'
  }

  return error?.response?.data?.message ?? error?.message
}

export function parseErrorMessage (error) {
  const errorCode = getErrorCode(error)
  return errorCode
}

export function createCrudRepository (resource) {
  return {
    getList: (...args) => CrudRepository.getList(resource, ...args),
    get: (...args) => CrudRepository.get(resource, ...args),
    post: (...args) => CrudRepository.post(resource, ...args),
    patch: (...args) => CrudRepository.patch(resource, ...args),
    delete: (...args) => CrudRepository.delete(resource, ...args),
  }
}

export default {
  parseErrorMessage,
  createCrudRepository,
}
