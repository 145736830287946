export default [
  {
    path: '/part-type',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'partTypeList',
        component: () => import('./containers/PartTypeListContainer'),
      },
    ],
  },
]
