import { toggle, set } from 'src/utils/vuex'

export const TOGGLE_MENU = toggle('menu')
export const TOGGLE_COLLAPSED_MENU = toggle('collapsedMenu')
export const SET_MENU = set('menu')
export const TOGGLE_SESSION_MENU = toggle('sessionMenu')
export const SET_SESSION_MENU = set('sessionMenu')

export function SHOW_NOTIFICATION (state, { success, message }) {
  state.notification = { visible: true, success, message }
}

export function HIDE_NOTIFICATION (state) {
  state.notification = {
    visible: false,
    message: '',
    success: state?.notification?.success,
  }
}
