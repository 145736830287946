import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMediator from './mediator-plugin'

import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
  },
  plugins: [
    createPersistedState({
      key: 'admin-polymem',
      paths: ['me', 'animation'],
      reducer (val) {
        return val?.me?.tokens ? val : {}
      },
    }),
    createMediator({
      listeners: [],
    }),
  ],
})
