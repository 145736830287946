export default [
  {
    path: '/document',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'DocumentList',
        component: () => import('./containers/DocumentListContainer'),
        children: [
          {
            path: 'restore',
            name: 'DocumentRestore',
            component: () => import('./containers/DocumentRestoreContainer'),
          },
          {
            path: 'create',
            name: 'DocumentCreate',
            component: () => import('./containers/DocumentCreateContainer'),
          },
          {
            path: ':documentId',
            name: 'DocumentUpdate',
            props: true,
            component: () => import('./containers/DocumentUpdateContainer'),
          },
        ],
      },
    ],
  },
]
