export default [
  {
    path: '/macro-kpi',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MacroKpi',
        component: () => import('./containers/MacroKpiContainer'),
        children: [
          {
            path: ':dateRangetype/:dataType',
            name: 'MacroKpiDetails',
            component: () => import('./containers/MacroKpiDetailsContainer'),
            props: true,
          },
        ],
      },
    ],
  },
]
