export function showNotification ({ commit }, { success, message }) {
  commit('SHOW_NOTIFICATION', { success, message })
}

export function showSuccessNotification ({ commit }, message) {
  commit('SHOW_NOTIFICATION', { success: true, message })
}

export function showErrorNotification ({ commit }, message) {
  commit('SHOW_NOTIFICATION', { success: false, message })
}

export function hideNotification ({ commit }) {
  commit('HIDE_NOTIFICATION')
}

export function toggleMenu ({ commit }) {
  commit('TOGGLE_MENU')
}

export function setMenu ({ commit, getters }, value) {
  if (getters.isMenuVisible !== value) {
    commit('SET_MENU', value)
  }
}

export function toggleCollapsedMenu ({ commit }) {
  commit('TOGGLE_COLLAPSED_MENU')
}

export function toggleSessionMenu ({ commit }) {
  commit('TOGGLE_SESSION_MENU')
}

export function setSessionMenu ({ commit, getters }, value) {
  if (getters.isSessionMenuVisible !== value) {
    commit('SET_SESSION_MENU', value)
  }
}
