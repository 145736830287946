export function notification (state) {
  return state.notification
}

export function isMenuVisible (state) {
  return state.menu
}

export function isMenuCollapsed (state) {
  return state.collapsedMenu
}

export function isSessionMenuVisible (state) {
  return state.sessionMenu
}
