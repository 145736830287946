export default [
  {
    path: '/rejection-cause',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'RejectionCauseList',
        component: () => import('./containers/RejectionCauseListContainer'),
        children: [
          {
            path: 'restore',
            name: 'RejectionCauseRestore',
            component: () => import('./containers/RejectionCauseRestoreContainer'),
          },
          {
            path: 'create',
            name: 'RejectionCauseCreate',
            component: () => import('./containers/RejectionCauseCreateContainer'),
          },
          {
            path: ':rejectionCauseId',
            name: 'RejectionCauseUpdate',
            props: true,
            component: () => import('./containers/RejectionCauseUpdateContainer'),
          },
        ],
      },
    ],
  },
]
