<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted () {
    this.$store.dispatch('me/init')
  },
}
</script>
<style>
.v-data-table__checkbox .v-icon {
  color: #27579ec4 !important;
}
.data-table__selected {
  background-color: #d8d8d8 !important;
}
</style>
