import { getErrorCode, parseErrorMessage } from 'src/services/repository/utils-repository'

import UserRepositories from './commons/user.repository'
import RoleRepositories from './commons/role.repository'
import PartTypeRepositories from './commons/partType.repository'
import PartNumberRepositories from './commons/partNumber.repository'
import PartRepositories from './commons/part.repository'
import OperationTypeRepositories from './commons/operationType.repository'
import OperationCategoryRepositories from './commons/operationCategory.repository'
import OperationRepositories from './commons/operation.repository'
import DocumentRepositories from './commons/document.repository'
import RecordTypeRepositories from './commons/recordType.repository'
import RejectionCauseRepositories from './commons/rejectionCause.repository'
import BatchRepositories from './commons/batch.repository'

import MeRepository from './commons/me.repository'

export default {
  getErrorCode,
  parseErrorMessage,
  me: MeRepository,
  user: UserRepositories,
  role: RoleRepositories,
  partType: PartTypeRepositories,
  partNumber: PartNumberRepositories,
  part: PartRepositories,
  operationType: OperationTypeRepositories,
  operationCategory: OperationCategoryRepositories,
  operation: OperationRepositories,
  document: DocumentRepositories,
  recordType: RecordTypeRepositories,
  rejectionCause: RejectionCauseRepositories,
  batch: BatchRepositories,
}
