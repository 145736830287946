import RepositoryService from 'src/services/repository'

const resource = 'part'

export default {
  ...RepositoryService.utils.createCrudRepository(resource),
  getDailyKpi: params => RepositoryService.crud.getList(`${resource}/daily-kpi`, params),
  getMacroKpi: params => RepositoryService.crud.getList(`${resource}/macro-kpi`, params),
  getInProgress: () => RepositoryService.crud.getList(`${resource}/in-progress`),
  getProduction: params => RepositoryService.crud.getList(`${resource}/production`, params),
  getListBySerialNumberRegex: params => RepositoryService.crud.getList(`${resource}/filter/serial-number`, params),
  getListTraceabilityAsc: params => RepositoryService.crud.getList(`${resource}/traceability-asc`, params),
  updateMany: params => RepositoryService.base.patch(`${resource}/updateMany`, params),
  setRejectedPartFromStock: params => RepositoryService.base.patch(`${resource}/setRejectedPartFromStock`, params),
  unsetRejectedPartFromStock: params => RepositoryService.base.patch(`${resource}/unsetRejectedPartFromStock`, params),
}
