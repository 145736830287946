import { set } from 'src/utils/vuex'

export const SET_USER = set('user')
export const SET_TOKENS = set('tokens')

export function LOGIN (state, {
  user,
  accessToken,
}) {
  state.user = user
  state.tokens = { accessToken }
}

export function LOGOUT (state) {
  state.user = null
  state.tokens = null
}
