export default [
  {
    path: '/part-number',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'PartNumberList',
        component: () => import('./containers/PartNumberListContainer'),
        children: [
          {
            path: 'restore',
            name: 'PartNumberRestore',
            component: () => import('./containers/PartNumberRestoreContainer'),
          },
          {
            path: 'create',
            name: 'PartNumberCreate',
            component: () => import('./containers/PartNumberCreateContainer'),
          },

        ],
      },
      {
        path: ':partNumberId',
        name: 'PartNumberUpdate',
        props: true,
        component: () => import('./containers/PartNumberUpdateContainer'),
        children: [
          {
            path: 'details',
            name: 'PartNumberUpdateDetails',
            props: true,
            component: () => import('./containers/PartNumberUpdateDetailsContainer'),
          },
          {
            path: 'routing',
            name: 'PartNumberUpdateRountingContainer',
            props: true,
            component: () => import('./containers/PartNumberRounting/PartNumberUpdateRountingContainer'),
          },
        ],
      },
    ],
  },
]
