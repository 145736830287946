export default [
  {
    path: '/operation-category',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'OperationCategoryList',
        component: () => import('./containers/OperationCategoryListContainer'),
        children: [
          {
            path: 'create',
            name: 'OperationCategoryCreate',
            component: () => import('./containers/OperationCategoryCreateContainer'),
          },
          {
            path: ':operationCategoryId',
            name: 'OperationCategoryUpdate',
            props: true,
            component: () => import('./containers/OperationCategoryUpdateContainer'),
          },
        ],
      },
    ],
  },
]
