import RepositoryService from 'src/services/repository'

const resource = 'batch'

export default {
  ...RepositoryService.utils.createCrudRepository(resource),
  restore: idList => RepositoryService.crud.post(`${resource}/restore`, { idList }),
  close: idList => RepositoryService.crud.post(`${resource}/close`, { idList }),
  forceClose: idList => RepositoryService.crud.post(`${resource}/force-close`, { idList }),
  getListWithPartStats: params => RepositoryService.crud.getList(`${resource}/withPartStats`, params),
  getOneWithStats: id => RepositoryService.base.get(`${resource}/${id}/stats`),
}
