export function user (state) {
  return state.user
}

export function userId (state, getters) {
  return getters.user?._id
}

export function fullname (state, getters) {
  return getters.user
    && `${getters.user.firstname} ${getters.user.lastname}`
}

export function role (state, getters) {
  return getters.user?.role ?? {}
}

export function tokens (state) {
  return state.tokens
}

export function isAuthenticated (state, getters) {
  return !!getters.user && !!getters.tokens
}
