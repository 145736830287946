export default [
  {
    path: '/record-type',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'RecordTypeList',
        component: () => import('./containers/RecordTypeListContainer'),
        children: [
          {
            path: 'restore',
            name: 'RecordTypeRestore',
            component: () => import('./containers/RecordTypeRestoreContainer'),
          },
          {
            path: 'create',
            name: 'RecordTypeCreate',
            component: () => import('./containers/RecordTypeCreateContainer'),
          },
          {
            path: ':recordTypeId',
            name: 'RecordTypeUpdate',
            props: true,
            component: () => import('./containers/RecordTypeUpdateContainer'),
          },
        ],
      },
    ],
  },
]
