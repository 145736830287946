export default [
  {
    path: '/operation-type',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'OperationTypeList',
        component: () => import('./containers/OperationTypeListContainer'),
        children: [
          {
            path: 'restore',
            name: 'OperationTypeRestore',
            component: () => import('./containers/OperationTypeRestoreContainer'),
          },
          {
            path: 'create',
            name: 'OperationTypeCreate',
            component: () => import('./containers/OperationTypeCreateContainer'),
          },
          {
            path: ':operationTypeId',
            name: 'OperationTypeUpdate',
            props: true,
            component: () => import('./containers/OperationTypeUpdateContainer'),
          },
        ],
      },
    ],
  },
]
