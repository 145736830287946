export default [
  {
    path: '/user',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'UserList',
        component: () => import('./containers/UserListContainer'),
        children: [
          {
            path: 'restore',
            name: 'UserRestore',
            component: () => import('./containers/UserRestoreContainer'),
          },
          {
            path: 'create',
            name: 'UserCreate',
            component: () => import('./containers/UserCreateContainer'),
          },
          {
            path: ':userId',
            name: 'UserUpdate',
            props: true,
            component: () => import('./containers/UserUpdateContainer'),
          },
        ],
      },
    ],
  },
]
