export default [
  {
    path: '/in-progress',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'InProgress',
        component: () => import('./containers/InProgressContainer'),
      },
    ],
  },
]
