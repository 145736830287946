export default [
  {
    path: '/batch',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'BatchList',
        component: () => import('./containers/BatchListContainer'),
        children: [
          {
            path: 'restore',
            name: 'BatchRestore',
            component: () => import('./containers/BatchRestoreContainer'),
          },
          {
            path: 'create',
            name: 'BatchCreate',
            component: () => import('./containers/BatchCreateContainer'),
          },
          {
            path: ':batchId',
            name: 'BatchDetails',
            props: true,
            component: () => import('./containers/BatchDetailsContainer'),
          },
        ],
      },
    ],
  },
]
