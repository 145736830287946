import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeRoutes from 'src/modules/home/routes'
import LoginRoutes from 'src/modules/login/routes'
import InProgressRoutes from 'src/modules/inProgress/routes'
import ProductionRoutes from 'src/modules/production/routes'
import TraceabilityDescRoutes from 'src/modules/traceabilityDesc/routes'
import TraceabilityAscRoutes from 'src/modules/traceabilityAsc/routes'
import ExportRoutes from 'src/modules/export/routes'
import UserRoutes from 'src/modules/user/routes'
import RoleRoutes from 'src/modules/role/routes'
import PartTypeRoutes from 'src/modules/partType/routes'
import OperationTypeRoutes from 'src/modules/operationType/routes'
import OperationCategoryRoutes from 'src/modules/operationCategory/routes'
import RecordTypeRoutes from 'src/modules/recordType/routes'
import RejectionCauseRoutes from 'src/modules/rejectionCause/routes'
import BatchRoutes from 'src/modules/batch/routes'
import PartNumberRoutes from 'src/modules/partNumber/routes'
import SetPartToUseRoutes from 'src/modules/setPartToUse/routes'
import SetPartUsedRoutes from 'src/modules/setPartUsed/routes'
import SetRejectedPartFromStock from 'src/modules/setRejectedPartFromStock/routes'
import UnsetRejectedPartFromStock from 'src/modules/unsetRejectedPartFromStock/routes'
import DailyKpiRoutes from 'src/modules/dailyKpi/routes'
import MacroKpiRoutes from 'src/modules/macroKpi/routes'
import DocumentRoutes from 'src/modules/document/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    ...LoginRoutes,
    ...HomeRoutes,
    ...InProgressRoutes,
    ...ProductionRoutes,
    ...TraceabilityDescRoutes,
    ...TraceabilityAscRoutes,
    ...ExportRoutes,
    ...UserRoutes,
    ...RoleRoutes,
    ...PartTypeRoutes,
    ...OperationTypeRoutes,
    ...OperationCategoryRoutes,
    ...RecordTypeRoutes,
    ...RejectionCauseRoutes,
    ...BatchRoutes,
    ...PartNumberRoutes,
    ...SetPartToUseRoutes,
    ...DailyKpiRoutes,
    ...SetPartUsedRoutes,
    ...SetRejectedPartFromStock,
    ...UnsetRejectedPartFromStock,
    ...DocumentRoutes,
    ...MacroKpiRoutes,
  ],
})

export default router
